import React from "react"
import Loadable from 'react-loadable';
import { connect } from "react-redux"
import { injectIntl } from "gatsby-plugin-intl"

import Loading from "../components/loading"
import Layout from "../containers/Layout"
import SEO from "../components/seo"
import * as actions from "../store/actions"


const LoadableSlider = Loadable({
  loader: () => import('../components/home').then(module => module.Slider),
  loading: Loading,
});
const LoadableAirQualitySection = Loadable({
  loader: () => import('../components/home').then(module => module.AirQualitySection),
  loading: Loading,
});
const LoadableMapsSection = Loadable({
  loader: () => import('../components/home').then(module => module.MapsSection),
  loading: Loading,
});
const LoadableAgreementsSection = Loadable({
  loader: () => import('../components/home').then(module => module.AgreementsSection),
  loading: Loading,
});
const LoadableLinksSection = Loadable({
  loader: () => import('../components/home').then(module => module.LinksSection),
  loading: Loading,
});
const LoadableAchievementsSection = Loadable({
  loader: () => import('../components/home').then(module => module.AchievementsSection),
  loading: Loading,
});

class LandingPage extends React.Component {

  componentDidMount() {
    document.documentElement.classList.remove("nav-open")
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <LoadableSlider />
        <LoadableAirQualitySection />
        <LoadableMapsSection />
        <LoadableAchievementsSection />
        <LoadableAgreementsSection />
        <LoadableLinksSection />
      </Layout >
    )
  }
}

const mapStateToProps = state => {
  return {
    regions: state.regions.regions,
    selectedRegion: state.regions.selectedRegion
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedRegion: regionCode => dispatch(actions.setSelectedRegion(regionCode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LandingPage))
